import React from "react";
import { Typography } from "antd";

export const NotFound = () => {
  return (
    <div>
      <div style={{ fontSize: 20, textAlign: "center", padding: "10%" }}>
        <Typography variant="h1">404: page not found!</Typography>
      </div>
    </div>
  );
};
